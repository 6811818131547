import React, { useEffect, useState } from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Layout from "../components/layout"
import { createPathWithCategories, parseUrl } from "../utils"
// import PropTypes from "prop-types"

function Search(props) {
  let initialVal = ""

  const getInitialVal = () => {
    if (typeof window !== "undefined") {
      let initialVal = localStorage.getItem("search-input")
      return initialVal
    }
  }

  const [searchQuery, setSearchQuery] = useState(getInitialVal)
  const [filteredPosts, setFilteredPosts] = useState(null)
  const [filteredCategoryPosts, setFilteredCategoryPosts] = useState(null)
  const [categorySelected, setCategorySelected] = useState("All")
  const [articleInCategory, setArticleInCategory] = useState({})
  // const [categoriesObject, setCategoriesObject] = useState()

  const data = [
    ...props.data.allWpVredempHrAdminPost.nodes,
    ...props.data.allWpVredempEmployeePost.nodes,
    ...props.data.allWpVrewardsHrAdminPost.nodes,
    ...props.data.allWpVrewardsManagerPost.nodes,
    ...props.data.allWpVrewardsEmployeePost.nodes,
    ...props.data.allWpVfitHrAdminPost.nodes,
    ...props.data.allWpVfitEmployeePost.nodes,
    ...props.data.allWpVpulseHrAdminPost.nodes,
    ...props.data.allWpVpulseEmployeePost.nodes,
    ...props.data.allWpVperksHrAdminPost.nodes,
    ...props.data.allWpVperksEmployeePost.nodes,
  ] //array

  const truncate = (string, length = 100) => {
    if (string.length > length) {
      var truncateStr = string.slice(0, length) + "..."
      return truncateStr
    }
    return string + "..."
  }

  const onClickHandler = e => {
    let clickedText = e.target.outerText
    let temp = clickedText.split("(")[0]
    setCategorySelected(temp)
  }

  useEffect(() => {
    if (searchQuery !== "") {
      const filteredposts = data.filter(e =>
        e.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      setFilteredPosts(filteredposts)
    } else {
      setFilteredPosts(null)
    }
  }, [searchQuery])

  useEffect(() => {
    if (searchQuery !== "") {
      const filteredposts = data.filter(e =>
        e.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      setFilteredCategoryPosts(filteredposts)
    } else {
      setFilteredCategoryPosts(null)
    }
  }, [searchQuery])

  useEffect(() => {
    if (filteredPosts !== null) {
      const filteredpoststemp = data.filter(e =>
        e.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      let filteredCategoryPosts = filteredpoststemp.filter(
        e => e.categories.nodes[0].name == categorySelected
      )
      setFilteredPosts(filteredCategoryPosts)
    }
  }, [categorySelected])

  useEffect(() => {
    if (filteredCategoryPosts !== null) {
      var filteredStructuredObj = structuredObject(filteredCategoryPosts)
      var originalStructuredObj = structuredObject(data)
    }
    if (filteredPosts !== null && filteredPosts !== undefined) {
      if (filteredCategoryPosts.length == 0) {
        for (let prop in originalStructuredObj) {
          let length = 0
          let temp = { ...temp, [prop]: length }
          setArticleInCategory(temp)
        }
      } else if (filteredStructuredObj !== undefined) {
        let temp = {}
        for (let prop in filteredStructuredObj) {
          let length =
            filteredStructuredObj[prop].length == null
              ? 1
              : filteredStructuredObj[prop].length
          temp = { ...temp, [prop]: length }
          setArticleInCategory(temp)
        }
      }
    }
  }, [filteredCategoryPosts, filteredPosts])

  const structuredObject = object => {
    let newObj = object.reduce((acc, value) => {
      let categoryName = `${value.categories.nodes[0].name}`
      if (Object.keys(acc).includes(categoryName)) {
        let arrOfObj = []
        let tempPrevVal = acc[categoryName]
        let tempNewVal = value
        if (tempPrevVal.length > 0) {
          arrOfObj = [tempNewVal, ...tempPrevVal]
        } else arrOfObj = [tempNewVal, tempPrevVal]
        let finalVal = { ...acc, [categoryName]: arrOfObj }
        return finalVal
      } else {
        let temp = {
          ...acc,
          [`${value.categories.nodes[0].name}`]: {
            title: value.title,
            slug: value.slug,
            excerpt: value.excerpt,
            link: value.link,
            date: value.date,
          },
        }
        return temp
      }
    }, {})
    return newObj
  }

  const renderCategories = () => {
    let categoriesObject = structuredObject(data)
    if (categoriesObject !== null && categoriesObject !== undefined) {
      return Object.keys(categoriesObject).map(key => (
        <>
          <li
            onClick={onClickHandler}
            className={`text-sm md:text-base list-none pt-2 cursor-pointer ${
              articleInCategory[key] ?? "hidden"
            }`}
          >
            {key}({articleInCategory[key] ?? 0})
          </li>
        </>
      ))
    }
  }

  const renderBreadCrumbs = e => {
    let link = e.link
    let linkArr = link.split("/")
    let res = []
    linkArr.pop()
    linkArr.shift()
    let breadCrumbsMap = {
      "vantage-rewards": "Vantage Rewards",
      "vantage-perks": "Vantage Perks",
      "vantage-pulse": "Vantage Pulse",
      "vantage-redemption": "Vantage Redemption",
      "vantage-fit": "Vantage Fit",
    }
    res.push(
      <div className="site-breadcrumb" style={{ marginBottom: "1rem" }}>
        <Link className="text-purple-500 hover:underline" to="/">
          Home
        </Link>
        &nbsp;>&nbsp;
        <Link
          className="text-purple-500 hover:underline"
          to={`/${linkArr[0]}/${linkArr[1]}`}
        >
          {breadCrumbsMap[linkArr[0]]}
        </Link>
        &nbsp;>&nbsp;
        <Link
          className="text-purple-500 hover:underline"
          to={`/${linkArr[0]}/${linkArr[1]}`}
        >
          {linkArr[1].charAt(0).toUpperCase() + linkArr[1].slice(1)}
        </Link>
        &nbsp;>&nbsp;
        <Link
          className="text-purple-500 hover:underline"
          to={`/${linkArr[0]}/${linkArr[1]}/${parseUrl(
            e.categories.nodes[0].name
          )}`}
        >
          {e.categories.nodes[0].name}
        </Link>
        &nbsp;&nbsp;
      </div>
    )
    return res
  }

  const rewardsArr = [
    <div className="bg-indigo-100 text-white py-2 px-3 mb-3">
      <h3>Vantage Rewards</h3>
    </div>,
  ]
  const pulseArr = [
    <div className="bg-indigo-100 text-white py-2 px-3 mb-3">
      <h3>Vantage Pulse</h3>
    </div>,
  ]
  const perksArr = [
    <div className="bg-indigo-100 text-white py-2 px-3 mb-3">
      <h3>Vantage Perks</h3>
    </div>,
  ]
  const redempArr = [
    <div className="bg-indigo-100 text-white py-2 px-3 mb-3">
      <h3>Vantage Redemption</h3>
    </div>,
  ]
  const vfitArr = [
    <div className="bg-indigo-100 text-white py-2 px-3 mb-3">
      <h3>Vantage Fit</h3>
    </div>,
  ]

  const renderSearchResults = () => {
    if (filteredPosts?.length == 0 || filteredPosts === null)
      return renderEmptySearchResults()
    else
      return (
        <>
          <h3 className="px-2 mb-10 text-sm md:text-base">
            {filteredPosts.length > 0 ? filteredPosts.length : "No"} results for
            '{searchQuery}' in <strong>{categorySelected}</strong> Category
          </h3>
          <div className="grid gap-3 md:grid-cols-3 grid-col-1">
            <div className="px-2 col-span-1">
              <div className="mb-4 font-bold border-b border-gray-light pb-2">
                By Categories
              </div>
              {renderCategories()}
            </div>
            <div className="float-right w-full px-2 col-span-2 border-opacity-95">
              {filteredPosts.map((e, key) => {
                if (
                  e.contentType.node.graphqlPluralName ==
                    "vrewardsHrAdminPosts" ||
                  e.contentType.node.graphqlPluralName ==
                    "vrewardsEmployeePosts" ||
                  e.contentType.node.graphqlPluralName == "vrewardsManagerPosts"
                ) {
                  rewardsArr.push(renderBreadCrumbs(e))
                  rewardsArr.push(
                    <div
                      key={key}
                      className={`border-b border-gray-light mb-12`}
                    >
                      <Link
                        className="font-bold hover:underline"
                        to={createPathWithCategories(
                          e.uri,
                          e.categories.nodes[0].slug,
                          e.slug
                        )}
                      >
                        {e.title}
                      </Link>
                      <p className="text-sm md:text-base mb-2">
                        {truncate(e.excerpt)}
                      </p>
                      <p className="text-xs text-gray-200">
                        Updated on {e.date}
                      </p>
                    </div>
                  )
                }
              })}
              {filteredPosts.map((e, key) => {
                if (
                  e.contentType.node.graphqlPluralName ==
                    "vpulseHrAdminPosts" ||
                  e.contentType.node.graphqlPluralName == "vpulseEmployeePosts"
                ) {
                  pulseArr.push(renderBreadCrumbs(e))
                  pulseArr.push(
                    <div
                      key={key}
                      className={`border-b border-gray-light mb-12`}
                    >
                      <Link
                        className="font-bold hover:underline"
                        to={createPathWithCategories(
                          e.uri,
                          e.categories.nodes[0].slug,
                          e.slug
                        )}
                      >
                        {e.title}
                      </Link>
                      <p className="text-sm md:text-base mb-2">
                        {truncate(e.excerpt)}
                      </p>
                      <p className="text-xs text-gray-200">
                        Updated on {e.date}
                      </p>
                    </div>
                  )
                }
              })}
              {filteredPosts.map((e, key) => {
                if (
                  e.contentType.node.graphqlPluralName ==
                    "vperksHrAdminPosts" ||
                  e.contentType.node.graphqlPluralName == "vperksEmployeePosts"
                ) {
                  perksArr.push(renderBreadCrumbs(e))
                  perksArr.push(
                    <div
                      key={key}
                      className={`border-b border-gray-light mb-12`}
                    >
                      <Link
                        className="font-bold hover:underline"
                        to={createPathWithCategories(
                          e.uri,
                          e.categories.nodes[0].slug,
                          e.slug
                        )}
                      >
                        {e.title}
                      </Link>
                      <p className="text-sm md:text-base mb-2">
                        {truncate(e.excerpt)}
                      </p>
                      <p className="text-xs text-gray-200">
                        Updated on {e.date}
                      </p>
                    </div>
                  )
                }
              })}
              {filteredPosts.map((e, key) => {
                renderBreadCrumbs(e)
                if (
                  e.contentType.node.graphqlPluralName ==
                    "vredempHrAdminPosts" ||
                  e.contentType.node.graphqlPluralName == "vredempEmployeePosts"
                ) {
                  redempArr.push(renderBreadCrumbs(e))
                  redempArr.push(
                    <div
                      key={key}
                      className={`border-b border-gray-light mb-12`}
                    >
                      <Link
                        className="font-bold hover:underline"
                        to={createPathWithCategories(
                          e.uri,
                          e.categories.nodes[0].slug,
                          e.slug
                        )}
                      >
                        {e.title}
                      </Link>
                      <p className="text-sm md:text-base mb-2">
                        {truncate(e.excerpt)}
                      </p>
                      <p className="text-xs text-gray-200">
                        Updated on {e.date}
                      </p>
                    </div>
                  )
                }
              })}
              {filteredPosts.map((e, key) => {
                if (
                  e.contentType.node.graphqlPluralName ==
                    "vfitHrAdminPosts" ||
                  e.contentType.node.graphqlPluralName == "vfitEmployeePosts"
                ) {
                  vfitArr.push(renderBreadCrumbs(e))
                  vfitArr.push(
                    <div
                      key={key}
                      className={`border-b border-gray-light mb-12`}
                    >
                      <Link
                        className="font-bold hover:underline"
                        to={createPathWithCategories(
                          e.uri,
                          e.categories.nodes[0].slug,
                          e.slug
                        )}
                      >
                        {e.title}
                      </Link>
                      <p className="text-sm md:text-base mb-2">
                        {truncate(e.excerpt)}
                      </p>
                      <p className="text-xs text-gray-200">
                        Updated on {e.date}
                      </p>
                    </div>
                  )
                }
              })}
              {rewardsArr}
              {pulseArr}
              {perksArr}
              {redempArr}
              {vfitArr}
            </div>
          </div>
        </>
      )
  }

  const renderEmptySearchResults = () => {
    return (
      <div className="px-2">
        <h3 className="text-sm md:text-base mb-4">No Results Found</h3>
        <div className="pr-20 text-sm md:text-base">
          Try searching another keyword.
        </div>
      </div>
    )
  }

  const onChangeHandler = e => {
    setSearchQuery(e.target.value)
  }

  return (
    <>
      <Layout includeSearchBar={false}>
        <div className="container px-6 py-20">
          <div className="float-left w-full md:w-2/6 relative mt-10 px-2">
            <input
              placeholder="Search Help Topics..."
              style={{
                paddingLeft: "3rem",
                border: "1px solid rgb(49 63 100 /.8)",
              }}
              onChange={onChangeHandler}
              className="vc-text-input pl-44"
              type="text"
              defaultValue={
                typeof window !== "undefined"
                  ? localStorage.getItem("search-input")
                  : ""
              }
              onKeyPress={e => {
                if (e.key == "Enter") return e.preventDefault()
              }}
            ></input>
            <svg
              className="absolute w-5"
              style={{ top: "13px", left: "17px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 56.966 56.966"
            >
              <path
                d="M55.146 51.887L41.588 37.786A22.926 22.926 0 0 0 46.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 0 0 .083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z"
                fill="#808080"
              ></path>
            </svg>
          </div>
          <div className="pt-28">
            {filteredPosts?.length == 0
              ? renderEmptySearchResults()
              : renderSearchResults()}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default function pageQuery() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpVrewardsHrAdminPost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVrewardsEmployeePost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVrewardsManagerPost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVpulseHrAdminPost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVpulseEmployeePost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVperksHrAdminPost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVperksEmployeePost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVredempHrAdminPost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVredempEmployeePost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVfitHrAdminPost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
          allWpVfitEmployeePost {
            nodes {
              slug
              title
              uri
              contentType {
                node {
                  graphqlPluralName
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              date(formatString: "DD MMMM, YYYY")
              link
              excerpt
            }
          }
        }
      `}
      render={data => <Search data={data} />}
    />
  )
}
